import { NavLink } from "react-router-dom";

function SectionServices1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center">
                        <div className="sx-head-s-title">Our Services</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">IT Solutions and Branding Services</h2>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="sx-our-services-bx">
                            <div className="row">
                                {/* Block one */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i class="fi fi-brands-app-store-ios"></i></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">App Development</h4>
                                                <p>Elevate your business with our tailored app development services, transforming ideas into cutting-edge digital solutions and immersive experiences.</p>
                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Block Two */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i class="fi fi-ss-chart-area"></i></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Data Science</h4>
                                                <p>Unleash the power of data with our comprehensive data science solutions, driving informed decision-making and uncovering valuable insights for your business.</p>
                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle active">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Block Three */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i class="fi fi-sr-shield-check"></i></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Cybersecurity Solutions</h4>
                                                <p>Fortify your digital fortress with our cybersecurity solutions, safeguarding your business against evolving threats and ensuring a resilient and secure online presence.</p>
                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i class="fi fi-sr-pencil-paintbrush"></i></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Logo & Brochure Design</h4>
                                                <p>Craft a lasting impression with our creative design services, where we specialize in bringing brands to life through distinctive logos and visually compelling brochures.</p>
                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i class="fi fi-brands-photoshop-express"></i></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Graphic Design</h4>
                                                <p>Fuel your brand's visual impact with our graphic design expertise, delivering captivating visuals that resonate and leave a lasting impression across various platforms.</p>
                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i class="fi fi-ss-layout-fluid"></i></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Flyer and Poster Design</h4>
                                                <p>Elevate your promotional efforts with our dynamic flyer and poster design services, creating eye-catching visuals that stand out and leave a lasting impact for your events and campaigns.</p>
                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i class="fi fi-bs-display-code"></i></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">IT & Branding Consultancy</h4>
                                                <p>Navigate the realms of IT and branding with our expert consultancy services, providing strategic guidance to optimize your technological endeavors and elevate your brand to new heights.</p>
                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionServices1;