import ItodoImage from "../../../elements/itodo-img";

function SectionAboutCompany2Inner() {
    return (
        <>
            <div className="sx-about-bx3-content-wrap">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="sx-about-bx3-media">
                            <div className="sx-dot-bx1"><ItodoImage src="images/about/dotted-pic2.png" alt="image" /></div>
                            <div className="sx-dot-bx2"><ItodoImage src="images/about/dotted-pic2.png" alt="image" /></div>
                            <div className="sx-ab3-img-outer">
                                <div className="sx-ab3-media">
                                    <ItodoImage src="images/about/p2.jpg" alt="media" />
                                </div>
                            </div>
                            <div className="sx-ab-circle-box">
                                <div className="sx-ab-circle-box-content">
                                    <span className="sx-exp">7+ Years Experience</span>
                                    <div className="sx-exp-title-outer">
                                        <span className="sx-exp-title-1">It & </span>
                                        <span className="sx-exp-title-2">Branding</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="sx-about-bx3-content">
                            <h3 className="sx-title">Our Mission.</h3>
                            <span className="sx-title-2">To create a significant impact in the IT and branding sector by fostering a community of diverse and skilled professionals.</span>
                            <p>We strive to collaborate seamlessly, leveraging our collective expertise to develop cutting-edge products that address technological challenges in Kenya, Africa, and beyond. </p>
                            <p> By securing jobs from corporates and institutions, we aim to fuel our growth, enabling us to contribute to the technological advancement of our community and continent.Our commitment extends to the creation of meaningful solutions and opportunities for the youth in our country and beyond. </p>
                            <h3 className="sx-title">Our Vision.</h3>
                            <span className="sx-title-2">To be a catalyst for transformative change in the IT and branding landscape, not just in Kenya but across Africa</span>
                            <p> We aspire to create a brand synonymous with innovation, empowerment, and opportunities for the youth.</p>
                            <p>By harnessing the power of technology, we envision a future where AsiliHub plays a pivotal role in solving complex issues on a global scale.</p>
                          
                            <div className="row sx-about-icon-bx3-column">
                                <div className="col-lg-6 col-md-6">
                                    <div className="sx-icon-bx-3">
                                        <div className="sx-media">
                                            <i className="flaticon-incoming-call" />
                                        </div>
                                        <div className="sx-icon-bx-info">
                                            <span className="sx-icon-bx-title-info">Contact Phone</span>
                                            <span className="sx-icon-bx-title">+2547 (013) 961 01</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="sx-icon-bx-3">
                                        <div className="sx-media">
                                            <i className="flaticon-email-1" />
                                        </div>
                                        <div className="sx-icon-bx-info">
                                            <span className="sx-icon-bx-title-info">Contact Mail</span>
                                            <span className="sx-icon-bx-title">info@asilihub.net</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hilite-title text-left p-l50 text-uppercase">
                <strong>About Us</strong>
            </div>
        </>
    )
}

export default SectionAboutCompany2Inner;