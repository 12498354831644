function SectionTestimonials1() {
    return (
        <>
            <div className="sx-testimonial-bx-1">
                <div className="owl-carousel testimonial-2-wrap owl-btn-vertical-center">
                    {/*One block*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-1">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>I believe that true innovation is not just about solving problems;
                                      it's about creating opportunities and leaving a lasting legacy in the ever-evolving landscape of technology and branding.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Alex Asili</h4>
                                    <span className="testimonial-position">Founder Of Company</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*One two*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-1">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>At AsiliHub, we're not just in charge; we're architects of possibility. 
                                    Leading with passion and precision, our team ensures that every endeavor transforms challenges into triumphs, setting new standards in the realms of IT and branding.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Eliah	Charlotte</h4>
                                    <span className="testimonial-position">IT Manager</span>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </>
    )
}

export default SectionTestimonials1;