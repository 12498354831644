export const bannerData = {
    about: {
        background: "images/banner/about.jpg",
        title: "About Us",
        crumb: "About Us",
        watermark: "It & Branding"
    },
    faq: {
        background: "images/banner/bnr-4.jpg",
        title: "Faq Page",
        crumb: "Faq Page",
        watermark: "FAQ"
    },
    gallery: {
        background: "images/banner/gallery.jpg",
        title: "Gallery Page",
        crumb: "Gallery Page",
        watermark: "Our Gallery"
    },
    icons: {
        background: "images/banner/bnr-4.jpg",
        title: "Icons",
        crumb: "icon-font",
        watermark: "It & Branding"
    },
    testimonials: {
        background: "images/banner/testimonial.jpg",
        title: "Testimonials",
        crumb: "Testimonials",
        watermark: "It Solution"
    },
    whychooseus: {
        background: "images/banner/bnr-8.jpg",
        title: "Why Choose us",
        crumb: "Why Choose us",
        watermark: "It Solution"
    },
    pricing: {
        background: "images/banner/pricing.jpg",
        title: "Price Table",
        crumb: "Price Table",
        watermark: "It Solution"
    },
    team: {
        background: "images/banner/bnr-2.jpg",
        title: "Team Members",
        crumb: "Team Members",
        watermark: "It-Branding Solution"
    },
    mission: {
        background: "images/banner/mission.jpg",
        title: "Mission & Vision",
        crumb: "Mission & Vision",
        watermark: "It Solution"
    },
    services: {
        background: "images/banner/success.jpg",
        title: "Our Success",
        crumb: "Our Success",
        watermark: "It $ Branding"
    },
    portfolio1: {
        background: "images/banner/bnr-4.jpg",
        title: "Portfolio Page",
        crumb: "Portfolio",
        watermark: "It Solution"
    },
    portfolio2: {
        background: "images/banner/bnr-5.jpg",
        title: "Portfolio-2 Page",
        crumb: "Portfolio-2 Page",
        watermark: "It Solution"
    },
    portfoliodetail: {
        background: "images/banner/bnr-7.jpg",
        title: "Portfolio Detail",
        crumb: "Portfolio Detail",
        watermark: "It Solution"
    },
    bloggrid: {
        background: "images/banner/bnr-1.jpg",
        title: "Blog Page",
        crumb: "Blog Page",
        watermark: "It Solution"
    },
    bloglistsidebar: {
        background: "images/banner/bnr-8.jpg",
        title: "Blog Archive",
        crumb: "Blog Single Grid",
        watermark: "It Solution"
    },
    blogdetail: {
        background: "images/banner/bnr-1.jpg",
        title: "Blog detail",
        crumb: "Blog detail",
        watermark: "It Solution"
    },
    blogdetailsidebar: {
        background: "images/banner/bnr-10.jpg",
        title: "Blog detail",
        crumb: "Blog detail",
        watermark: "It Solution"
    },
    contact: {
        background: "images/banner/contact_us.jpg",
        title: "Contact Us",
        crumb: "Contact Us",
        watermark: "Branding & IT"
    }
}