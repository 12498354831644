import React from "react";
import { NavLink } from "react-router-dom";

export function SectionPricing1Inner() {
  return (
    <>
      <div className="container">
        {/* TITLE START */}
        <div className="section-head center">
          <div className="sx-head-s-title">Price Table</div>
          <div className="sx-head-l-title">
            <h2 className="sx-title">Our Pricing Table</h2>
          </div>
        </div>
        {/* TITLE END */}

        <div className="pricing-table wow fadeInDown" data-wow-duration="1000ms">
              <div className="pricing-category">
                <h3 className="category-title">Branding</h3>
              </div>
              <div className="pricing-details">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Small Scale(Kshs)</th>
                      <th>Large Scale(Kshs)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Round Neck Shirt</td>
                      <td>550-1000</td>
                      <td>450-700</td>
                    </tr>
                    <tr>
                      <td>Polo Shirts</td>
                      <td>800-1200</td>
                      <td>750-100</td>
                    </tr>
                    <tr>
                      <td>Official Shirts</td>
                      <td>1900-2500</td>
                      <td>1800-2000</td>
                    </tr>
                    <tr>
                      <td>Hoodies</td>
                      <td>2000-3000</td>
                      <td>1800-2500</td>
                    </tr>
                    <tr>
                      <td>Caps</td>
                      <td>500-700</td>
                      <td>450-600</td>
                    </tr>
                    <tr>
                      <td>Mugs</td>
                      <td>450-800</td>
                      <td>400-600</td>
                    </tr>
                    <tr>
                      <td>Umbrellas</td>
                      <td>800-950</td>
                      <td>600-850</td>
                    </tr>
                    <tr>
                      <td>Stickers</td>
                      <td>Depends on dimensions</td>
                      <td>Depends on dimensions</td>
                    </tr>
                    <tr>
                      <td>Banners</td>
                      <td>Depends on dimensions</td>
                      <td>Depends on dimensions</td>
                    </tr>
                    <tr>
                      <td>Roll-up Banners</td>
                      <td>7900-8500</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Tear drops</td>
                      <td>Varies</td>
                      <td>Varies</td>
                    </tr>
                    <tr>
                      <td>Pens</td>
                      <td>50-100</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Notebooks</td>
                      <td>600-1000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Business Cards</td>
                      <td>20-50</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Flyers</td>
                      <td>10-30</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Selft-inking stamps</td>
                      <td>2000-3000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Reciept Books</td>
                      <td>1000-3000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Invoice</td>
                      <td>1000-3000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Quotation Book</td>
                      <td>1000-3000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Delivery Note</td>
                      <td>1000-3000</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pricing-category">
                <h3 className="category-title">Graphic Design</h3>
              </div>
              <div className="pricing-details">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Small Scale(Kshs)</th>
                      <th>Large Scale(Kshs)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Logo Design</td>
                      <td>2500-5000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Posters</td>
                      <td>1000-3000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Rate Card</td>
                      <td>1000-3000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Wedding Card</td>
                      <td>2000-3000</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Company Profile</td>
                      <td>2000-3000</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
      </div>
    </>
  );
}

function SectionPricing1() {
  return (
    <>
      <div className="pricing-section">
        <SectionPricing1Inner />
      </div>
    </>
  );
}

export default SectionPricing1;
