import SectionTestimonials1 from "./section-testimonials1";

function SectionCaseStudy1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-case-study-outer wow fadeInDown" data-wow-duration="1000ms">
                <div className="container">
                  
                    <div className="section-content">
                        {/*testimonial section */}
                        <SectionTestimonials1 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionCaseStudy1;