import ItodoImage from "../../../elements/itodo-img";

function GeneralFAQContent() {
    return (
        <>
            <div className="aon-faq-content">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion">
                                {/*One*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1" aria-expanded="false">
                                        What services does AsiliHub offer??
                                    </button>
                                    <div id="FAQ1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                            AsiliHub specializes in a wide array of services, including IT consultancy, app development, data science solutions, branding, logo design, and graphic design. Our expertise extends to creating innovative solutions tailored to meet the unique needs of our clients.
                                        </div>
                                    </div>
                                </div>
                                {/*Two*/}
                                <div className="accordion-item">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2" aria-expanded="true">
                                    What sets AsiliHub apart from other IT and branding companies?
                                    </button>
                                    <div id="FAQ2" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        AsiliHub distinguishes itself through a combination of innovative solutions, client-centric approach, timely delivery, and a commitment to excellence. Our team is dedicated to transforming challenges into success stories, setting new standards in the IT and branding industry.
                                        </div>
                                    </div>
                                </div>
                                {/*Three*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ3" aria-expanded="false">
                                    Can AsiliHub handle both local and international branding projects?
                                    </button>
                                    <div id="FAQ3" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        Absolutely. While our IT solutions have a global reach, our branding services are tailored to both local and international clients. We pride ourselves on crafting impactful and culturally resonant branding strategies for diverse audiences.
                                        </div>
                                    </div>
                                </div>
                                {/*Four*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ4" aria-expanded="false">
                                    What industries does AsiliHub cater to?
                                    </button>
                                    <div id="FAQ4" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        AsiliHub's expertise spans across various industries, including healthcare, technology, education, and more. Our multidisciplinary approach allows us to tailor solutions for a wide range of sectors.
                                        </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5" aria-expanded="false">
                                    Who founded AsiliHub, and when was it established?
                                    </button>
                                    <div id="FAQ5" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        AsiliHub was founded by Alex Asili in 2022. With a vision for creating opportunities in the IT sector and building a prominent branding business, Alex Asili leads the company towards innovation and excellence.
                                        </div>
                                    </div>
                                </div>
                                {/*Six*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ6" aria-expanded="false">
                                    How can I contact AsiliHub for inquiries or collaborations?
                                    </button>
                                    <div id="FAQ6" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        Feel free to reach out to us through our Contact page on the website, where you can fill out a form or find our contact details. We welcome inquiries, partnership opportunities, and collaboration discussions.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="aon-faq-1-media">
                            <ItodoImage src="images/faq-img-1.jpg" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function HelpFAQContent() {
    return (
        <>
            <div className="aon-faq-content">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion-1">
                                {/*One*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1-1" aria-expanded="false">
                                    How can I request a quote for a specific project?
                                    </button>
                                    <div id="FAQ1-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        To request a quote, simply navigate to our 'Contact Us' page and provide details about your project. Our team will review your requirements and promptly get back to you with a tailored proposal.
                                        </div>
                                    </div>
                                </div>
                                {/*Two*/}
                                <div className="accordion-item">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2-1" aria-expanded="true">
                                    What is the typical timeline for project delivery at AsiliHub?
                                    </button>
                                    <div id="FAQ2-1" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        Project timelines vary based on the scope and complexity. However, we prioritize timely delivery without compromising quality. During the initial consultation, we'll discuss and agree upon a realistic timeline for your project.
                                        </div>
                                    </div>
                                </div>
                                {/*Three*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ3-1" aria-expanded="false">
                                    Does AsiliHub provide ongoing support after project completion?
                                    </button>
                                    <div id="FAQ3-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        Yes, we offer post-project support and maintenance services to ensure the continued success of your solution. Our team is available to address any issues, implement updates, and provide ongoing assistance as needed.
                                        </div>
                                    </div>
                                </div>
                                {/*Four*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ4-1" aria-expanded="false">
                                    How can I get technical support for an existing project with AsiliHub?
                                    </button>
                                    <div id="FAQ4-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        If you require technical support for an existing project, please reach out to our dedicated support team through the contact details provided in your project documentation. We prioritize swift responses to ensure minimal disruptions.
                                        </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5-1" aria-expanded="false">
                                    Can I schedule a consultation with AsiliHub to discuss my project ideas?
                                    </button>
                                    <div id="FAQ5-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        Yes, we encourage it! You can schedule a consultation through our website, where we'll discuss your project ideas, goals, and provide insights on how AsiliHub can bring your vision to life.
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="aon-faq-1-media">
                            <ItodoImage src="images/faq-img-2.jpg" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionFAQContent() {
    return (
        <>
            <div className="tab-content">
                {/*1*/}
                <div className="tab-pane fade show active" id="day_1" role="tabpanel">
                    <GeneralFAQContent />
                </div>
                {/*2*/}
                <div className="tab-pane fade" id="day_2" role="tabpanel">
                    <HelpFAQContent />
                </div>
            </div>
        </>
    )
}

export default SectionFAQContent;