import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner";
import SectionClients2 from "../../sections/home/index2/section-clients2";

function TestimonialsPage() {
    return (
        <>
            <Banner _data={bannerData.testimonials} />

            <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
                {/* TESTIMONIAL START */}
                <div className="container">
                    <div className="section-content">
                        {/* TITLE START */}
                        <div className="section-head center">
                            <div className="sx-head-s-title">Our Testimonials</div>
                            <div className="sx-head-l-title">
                                <h2 className="sx-title">What Clients Say?</h2>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="masonry-outer mfp-gallery news-grid clearfix row">
                            {/* COLUMNS 1 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position"> CEO-Tech Innovations Inc</span>
                                            <h4 className="testimonial-name">John Waswa</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Working with AsiliHub has been a game-changer for our company. Their innovative approach  branding elevated our brand presence. Timely delivery and a dedicated team make them our preferred choice.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 2 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Marketing Director, GlobalTech Solutions</span>
                                            <h4 className="testimonial-name">Delilah Brooklyn</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Choosing AsiliHub for our IT consultancy needs was a strategic decision. Their team's expertise in solving complex tech challenges has greatly contributed to the success of our projects. Highly recommended!</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 3 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">IT Manager, FutureTech Systems</span>
                                            <h4 className="testimonial-name">Davidson Wahome</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>As a startup founder, I appreciate the agility and understanding of the tech startup landscape.  Their support in branding played a pivotal role in shaping our identity.Reliable partner for any startup journey.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 4 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position"> Founder, InnovateTech Startups</span>
                                            <h4 className="testimonial-name">Emily Turner</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>AsiliHub's data science solutions have revolutionized how we analyze student data. A trusted ally for anyone seeking data-driven excellence.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 5 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Director, EduConnect Solutions</span>
                                            <h4 className="testimonial-name">Michael Adams</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>The branding expertise transformed our sustainability message into a compelling narrative. From brand strategy to visual elements, they captured our ethos perfectly. </p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 6 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position"> Head of Marketing, EcoSolutions Ltd.</span>
                                            <h4 className="testimonial-name">Sarah Johnson</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>AsiliHub exceeded our expectations in delivering a comprehensive branding strategy. A reliable partner for any organization aiming to stand out.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* TESTIMONIAL END */}
            </div>

            <SectionClients2 />
        </>
    )
}

export default TestimonialsPage;