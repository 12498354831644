import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import SectionTestimonials2 from "./section-testimonials2";

export function SectionWhyChooseUs2Inner() {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <ItodoImage src="images/about/p3.jpg" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title">Why Choose Us For IT & Branding Needs ?</h2>
                        <p>We stand out as your premier choice for IT and branding needs. Our unwavering commitment to innovation, client-centric approach, and a proven track record of delivering timely, high-quality solutions set us apart in the dynamic landscape of technology and branding.</p>
                        <ul className="sx-arrow-circle-checked">
                            <li>Timely Delivery - Consistently exceeding project timelines with precision and efficiency.</li>
                            <li>Innovative Solutions - Pioneering creativity in IT and branding, bringing fresh perspectives to every project.</li>
                            <li>Client-Centric Approach - Placing clients at the heart of our processes, ensuring their vision is not only met but exceeded.</li>
                            <li>Adaptive Agility - Embracing change and staying ahead in a dynamic industry, adapting to the evolving needs of our clients.</li>
                        </ul>

                        <NavLink to="/why-choose-us" className="site-button sx-btn-primary icon sx-btn-lg">
                            <i className="fa  fa-long-arrow-right" />
                            View All
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionWhyChooseUs2() {
    return (
        <>
            <div className="section-full  p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUs2Inner />
                        </div>
                        {/*testimonial section */}
                        <SectionTestimonials2 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs2;